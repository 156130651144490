<template>
    <v-container fluid>
        <v-row dense>
            <v-col cols="8" md="8">
                <v-card :elevation="1">
                    <v-toolbar color="white" :elevation="1" height="30px">
                        <v-toolbar-title class="subtitle-2">Holidays</v-toolbar-title>
                    </v-toolbar>

                    <v-card-text>
                        <div class="pb-1">
                            <v-btn
                                outlined
                                color="primary"
                                dark
                                @click.stop="dialog = true"
                                class="mr-2"
                            >
                                New Holiday
                            </v-btn>
                            <v-btn
                                outlined
                                class="mr-4"
                                color="grey darken-2"
                                @click="setToday"
                            >
                                Today
                            </v-btn>
                            <v-btn
                                fab
                                text
                                small
                                color="grey darken-2"
                                @click="prev"
                            >
                                <v-icon small> mdi-chevron-left </v-icon>
                            </v-btn>
                            <v-btn
                                fab
                                text
                                small
                                color="grey darken-2"
                                @click="next"
                            >
                                <v-icon small> mdi-chevron-right </v-icon>
                            </v-btn>

                            <span v-if="$refs.calendar">
                                {{ $refs.calendar.title }}
                            </span>
                        </div>
                        <v-calendar
                            ref="calendar"
                            v-model="focus"
                            color="primary"
                            :events="holidays"
                            :event-color="getEventColor"
                            :type="type"
                            @click:event="showEvent"
                            @click:more="viewDay"
                            @click:date="viewDay"
                        ></v-calendar>
                        <v-menu
                            v-model="selectedOpen"
                            :close-on-content-click="false"
                            :activator="selectedElement"
                            offset-x
                        >
                            <v-card
                                color="grey lighten-4"
                                min-width="350px"
                                flat
                            >
                                <v-toolbar :color="selectedEvent.color" dark>
                                    <v-btn
                                        v-if="$store.state['user'].role == 'Admin'"
                                        icon
                                        @click="deleteHoliday(selectedEvent.id)"
                                    >
                                        <v-icon>mdi-delete</v-icon>
                                    </v-btn>
                                    <v-toolbar-title
                                        v-html="selectedEvent.name + '<br>' + selectedEvent.start + ' ~ ' + selectedEvent.end"
                                    ></v-toolbar-title>
                                </v-toolbar>

                                <v-card-text>
                                    <v-alert
                                    dense
                                    outlined
                                    type="error"
                                    v-if="Object.keys(validation_errors).length > 0"
                                    >
                                        <ul>
                                            <li v-for="(errors, field) in validation_errors" :key="field">
                                                {{ field }}
                                                <ul>
                                                    <li v-for="(error, sl) in errors" :key="sl">
                                                        {{ error }}
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </v-alert>
                                    <v-textarea
                                        v-model="selectedEvent.details"
                                        type="text"
                                    >
                                    </v-textarea>
                                </v-card-text>

                                <v-card-actions>
                                    <v-btn
                                        text
                                        color="secondary"
                                        @click="selectedOpen = false"
                                    >
                                        Cancel
                                    </v-btn>
                                    <v-btn
                                        v-if="$store.state['user'].role != 'User'"
                                        text
                                        color="secondary"
                                        @click="updateHoliday(selectedEvent)"
                                        :loading="loading2"
                                    >
                                        Update
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-menu>
                    </v-card-text>
                </v-card>
            </v-col>

            <!-- weekend start -->
            <v-col cols="4" md="4">
                <v-card height="100%" :elevation="1">
                    <v-toolbar color="white" :elevation="1" height="30px">
                        <v-toolbar-title class="subtitle-2">Weekend</v-toolbar-title>
                    </v-toolbar>
                    <v-card-text class="py-0">
                        <div v-for="(day,ind) in days" :key="ind">
                            <v-checkbox
                                v-model="weekends"
                                :value="day"
                                :label="day"
                                hide-details
                                dense
                                class="my-2"
                            ></v-checkbox>
                        </div>
                    </v-card-text>
                    <v-col cols="12" md="12" class="text-right">
                        <v-btn height="30px" dark color="light-blue darken-2 mr-1" @click="updateWeekend" :loading="loading" :elevation="1">Save</v-btn>
                        <v-btn height="30px" dark color="deep-orange" :elevation="1">Cancel</v-btn>
                    </v-col>
                </v-card>
            </v-col>
            <!-- weekend end -->
        </v-row>

        <!-- dialog holiday start -->
        <v-dialog v-model="dialog" max-width="500">
            <v-form 
                ref="holidayForm"
                @submit.prevent="addHoliday"
            >
                <v-card>
                    <v-toolbar dense dark :elevation="1" color="primary">
                        <v-toolbar-title class="subtitle-2">Holiday Information</v-toolbar-title>
                    </v-toolbar>

                    <v-alert
                    dense
                    outlined
                    type="error"
                    v-if="Object.keys(validation_errors).length > 0"
                    >
                        <ul>
                            <li v-for="(errors, field) in validation_errors" :key="field">
                                {{ field }}
                                <ul>
                                    <li v-for="(error, sl) in errors" :key="sl">
                                        {{ error }}
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </v-alert>

                    <v-card-text>
                        <v-row>
                            <v-col class="py-2">
                                <v-text-field
                                    v-model="holiday.name"
                                    dense
                                    hide-details
                                    outlined
                                    label="Holiday Name"
                                    :rules="[() => !!holiday.name || '']"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col class="py-2">
                                <v-text-field
                                    v-model="holiday.details"
                                    dense
                                    hide-details
                                    outlined
                                    label="Details"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col class="py-2">
                                <v-menu 
                                    max-width="290" 
                                    v-model="menu"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-text-field
                                            dense
                                            hide-details
                                            readonly
                                            outlined
                                            v-on="on"
                                            v-model="holiday.start"
                                            label="From Date"
                                            :rules="[() => !!holiday.start || '']"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker no-title v-model="holiday.start" @input="menu = false"></v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col class="py-2">
                                <v-menu 
                                    max-width="290" 
                                    v-model="menu2"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-text-field
                                            dense
                                            hide-details
                                            readonly
                                            outlined
                                            v-on="on"
                                            v-model="holiday.end"
                                            label="To Date"
                                            :rules="[() => !!holiday.end || '']"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker no-title v-model="holiday.end" @input="menu2 = false"></v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col class="py-2">
                                <v-color-picker
                                    mode="hexa"
                                    v-model="holiday.color"
                                ></v-color-picker>
                            </v-col>
                        </v-row>
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            type="submit"
                            color="primary"
                            :loading="loading2"
                        >
                            Create Holiday
                        </v-btn>

                        <v-btn
                            color="error"
                            @click.stop="cancel"
                        >
                            Cancel
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>
        <!-- dialog holiday start -->
    </v-container>
</template>

<script>
import utility from '../../mixins/utility.mixin';
export default {
    mixins: [utility],
    data() {
        return {
            holiday: {
                id: null,
                name: "",
                details: "",
                start: this.toISOLocal().substr(0, 10),
                end: this.toISOLocal().substr(0, 10),
                color: "#1976D2",
            },
            focus: "",
            type: "month",
            dialog: false,
            holidays: [],
            selectedEvent: {},
            selectedElement: null,
            selectedOpen: false,
    
            weekends: [],
            days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
            loading: false,
    
            menu: false,
            menu2: false,
    
            validation_errors: {},
            loading2: false,
        }
    },
    mounted() {
        this.$refs.calendar.checkChange();
        this.getHolidays();
        this.getWeekends();
        
    },
    methods: {
        async updateWeekend() {

            this.loading = true;

            let data = {
                url: "/update_weekends",
                weekends: this.weekends,
            };

            await this.$store.dispatch(
                "general/updateWeekends",
                data
            );
            this.loading = false;

        },
        async getWeekends(){
            this.weekends = Object.values(
                await this.$store.dispatch("general/getWeekends", {
                    apiParams: {}, options: { returnData: true }
                })
            );
        },
        async getHolidays() {
            let date_from = this.$refs.calendar.lastStart.date;
            let date_to = this.$refs.calendar.lastEnd.date;

            await this.$store.dispatch('holiday/getHolidays', {
                apiParams: {date_from, date_to}
            });
            this.holidays = this.$store.getters["holiday/holidays"]
        },
        async addHoliday() {
            if (!this.$refs.holidayForm.validate()) return;
            this.loading2 = true;

            let resObj = await this.$store.dispatch('holiday/saveHoliday', this.holiday);

            if(resObj.hasOwnProperty("validation_errors")){
                this.validation_errors = resObj.validation_errors;
            }
            
            this.loading2 = false;

            if(resObj.isSuccess) {
                this.getHolidays();
                this.clearFrom();
                this.dialog = false;
            }
        },
        cancel(){
            this.clearFrom();
            this.dialog = false;
        },
        async updateHoliday(holiday) {
            this.loading2 = true;
            let resObj = await this.$store.dispatch('holiday/saveHoliday', holiday);

            if(resObj.hasOwnProperty("validation_errors")){
                this.validation_errors = resObj.validation_errors;
            }
            
            this.loading2 = false;

            if(resObj.isSuccess) {
                this.getHolidays();
                this.validation_errors = {};
                this.selectedOpen = false;
            }
        },
        async deleteHoliday(id) {
            let deleteConf = confirm('Are you sure?');
            if(deleteConf == false){
                return;
            }

            let isSuccess = await this.$store.dispatch('holiday/deleteHoliday', id);
            if(isSuccess) {
                this.getHolidays();
                this.selectedOpen = false;
            }
        },
        clearFrom() {
            this.holiday = {
                id: null,
                name: "",
                details: "",
                start: this.toISOLocal().substr(0, 10),
                end: this.toISOLocal().substr(0, 10),
                color: "#1976D2",
            };

            this.validation_errors = {};
            this.$refs.holidayForm.resetValidation();
        },
        viewDay({ date }) {
            this.dialog = true;
            this.holiday.start = date;
            this.holiday.end = date;
            this.focus = date;
        },
        getEventColor(event) {
            return event.color;
        },
        async setToday() {
            this.focus = "";
            await new Promise(r => setTimeout(r, 1000));
            this.getHolidays();
        },
        async prev() {
            await this.$refs.calendar.prev();
            this.getHolidays();
        },
        async next() {
            await this.$refs.calendar.next();
            this.getHolidays();
        },
        showEvent({ nativeEvent, event }) {
            const open = () => {
                this.selectedEvent = event;
                this.selectedElement = nativeEvent.target;
                setTimeout(() => {
                    this.selectedOpen = true;
                }, 10);
            };

            if (this.selectedOpen) {
                this.selectedOpen = false;
                setTimeout(open, 10);
            } else {
                open();
            }

            nativeEvent.stopPropagation();
        },
    },
};
</script>

<style>
</style>